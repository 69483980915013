import Ad from '../Model/f_ad';
import Copylight from '../Model/f_copylight';

function Footer() {
    return (
        <div className="">
            <Ad />
            <Copylight />
        </div>
    )
}
export default Footer;